import "core-js/modules/es.array.push.js";
import "core-js/modules/es.array.reduce.js";
import VueApexCharts from 'vue-apexcharts';
import store from '@/state/store.js';
export default {
  //     props: {
  //     action: {
  //       type: String,
  //       required: true,
  //     },
  //     viewType: {
  //       required: true,
  //       type: String,
  //     },
  //   },
  components: {
    apexcharts: VueApexCharts
  },
  data: function () {
    return {
      loading: false,
      route: '',
      colors: [],
      routeLabels: ["SMS OTP", "VOICE OTP", "HIGH PRIORITY ROUTE", "STANDARD ROUTE"],
      routeCount: [],
      viewType: '',
      action: '',
      formData: {
        date_from: "",
        date_to: ""
      },
      rules: {
        date_from: [{
          required: true,
          message: "Date from is required",
          trigger: ["change", "blur"]
        }],
        date_to: [{
          required: true,
          message: "Date to is required",
          trigger: ["change", "blur"]
        }]
      }
    };
  },
  methods: {
    reporting() {
      this.loading = true;
      this.routeCount = [];
      this.routeLables = [];
      store.dispatch("admin/reporting/getQualityRoutesReport", {
        to: this.to,
        from: this.from
      }).then(response => {
        if (response.length) {
          response.forEach(data => {
            this.routeCount.push(data.Counts);
            this.routeLables.push(data.status);
          });
        }
      }).catch(err => {
        this.loading = false;
        if (err.response.data == undefined) {
          this.errors.push("An error occured try again.");
        }
        if (err.response && err.response.status === 404) {
          this.errors = Object.values(err.response.data.data);
        }
      }).finally(() => this.loading = false);
    },
    button() {
      this.loading = true;
      store.dispatch("admin/reporting/getQualityRoutesReport", {
        to: this.to,
        from: this.from
      }).then(response => {
        this.routeCount = [];
        this.routeLables = [];
        Object.values(response.counts).forEach(count => {
          this.routeCount.push(count);
        });
      }).finally(() => {
        this.loading = false;
      });
    }
  },
  created() {
    this.routeLabels.forEach(label => {
      if (label.toLowerCase() === "SMS OTP".toLocaleLowerCase()) {
        this.colors.push('#36D962');
      } else if (label.toLowerCase() === 'VOICE OTP'.toLocaleLowerCase()) {
        this.colors.push('#000');
      } else if (label.toLowerCase() === 'HIGH PRIORITY ROUTE'.toLocaleLowerCase()) {
        this.colors.push('#30A1F0');
      } else if (label.toLowerCase() === 'STANDARD ROUTE'.toLocaleLowerCase()) {
        this.colors.push('#FF0000');
      }
    });
  },
  computed: {
    chartOptions: function () {
      return {
        colors: this.colors,
        tooltip: {
          y: {
            formatter: undefined,
            title: {
              formatter: seriesName => {
                return seriesName.replace(/_/g, ' ').replace(/\w\S*/g, function (txt) {
                  return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
                });
              }
            }
          }
        },
        legend: {
          fontSize: '14px',
          fontWeight: 400,
          floating: false,
          offsetX: 0,
          offsetY: 0,
          height: 200,
          itemMargin: {
            horizontal: 30,
            vertical: 15
          },
          formatter: function (seriesName, opts) {
            let total = 0;
            let series = opts.w.globals.series;
            let singleSeries = opts.w.globals.series[opts.seriesIndex];
            for (let i = 0; i < series.length; i++) {
              total += series[i];
            }
            let perCentage = parseInt(singleSeries / total * 100);
            seriesName = seriesName.replace(/_/g, ' ').replace(/\w\S*/g, function (txt) {
              return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
            });
            return `
                        <div style='display:inline-grid; width:300px'> 
                            <div style='grid-area: 1 / 1 / 2 / 2; place-self: start; width:100px;margin:0' >${singleSeries}</div>
                            <div style='grid-area: 1 / 2 / 2 / 3; place-self: center;text-align:center;width:100px'>${seriesName}</div>
                            <div style='grid-area: 1 / 3 / 2 / 4; place-self: end; width:100px;text-align:right;'>${perCentage}% </div>
                        </div>
                        `;
          }
        },
        dataLabels: {
          enabled: false
        },
        labels: this.routeLabels,
        plotOptions: {
          pie: {
            size: 200,
            expandOnClick: false,
            donut: {
              size: "88%",
              background: 'transparent',
              labels: {
                show: true,
                name: {
                  show: true,
                  offsetY: -10,
                  formatter: function (val) {
                    // remove underscore and capitalize each word after a space
                    return val.replace(/_/g, ' ').replace(/\w\S*/g, function (txt) {
                      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
                    });
                  }
                },
                value: {
                  show: true,
                  fontSize: '16px',
                  color: "#575761",
                  fontStyle: "normal",
                  fontWeight: "bold",
                  fontSize: "29px",
                  lineHeight: "32px",
                  formatter: function (val) {
                    return val;
                  }
                },
                total: {
                  show: true,
                  label: 'Total counts',
                  color: '#575761',
                  fontStyle: "normal",
                  fontWeight: "normal",
                  fontSize: "12px",
                  lineHeight: "13px",
                  formatter: function (w) {
                    return w.globals.seriesTotals.reduce((a, b) => {
                      return a + b;
                    }, 0);
                  }
                }
              }
            }
          }
        },
        series: this.routeCount,
        responsive: [{
          breakpoint: 768,
          options: {
            legend: {
              position: "bottom",
              height: 200,
              itemMargin: {
                horizontal: 0,
                vertical: 0
              },
              formatter: function (seriesName, opts) {
                let total = 0;
                let series = opts.w.globals.series;
                let singleSeries = opts.w.globals.series[opts.seriesIndex];
                for (let i = 0; i < series.length; i++) {
                  total += series[i];
                }
                let perCentage = parseInt(singleSeries / total * 100);
                // series name remove underscore and capitalize first letter of each word
                seriesName = seriesName.replace(/_/g, ' ').replace(/\w\S*/g, function (txt) {
                  return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
                });
                return `
                        <div style='display:inline-grid; width:200px' > 
                            <div style='grid-area: 1 / 1 / 2 / 2; place-self: start; width:50px;margin:0' >${singleSeries}</div>
                            <div style='grid-area: 1 / 2 / 2 / 3; place-self: center;text-align:center;width:100px'>${seriesName}</div>
                            <div style='grid-area: 1 / 3 / 2 / 4; place-self: end; width:50px;text-align:right;'>${perCentage}% </div>
                        </div>
                        `;
              }
            }
          }
        }, {
          breakpoint: 1235,
          options: {
            legend: {
              position: "bottom",
              height: 220,
              itemMargin: {
                horizontal: 2,
                vertical: 0
              }
            }
          }
        }]
      };
    }
  },
  mounted() {
    this.loading = true;
    store.dispatch("admin/reporting/getQualityRoutesReport", {
      to: "",
      from: ""
    }).then(response => {
      Object.values(response.counts).forEach(count => {
        this.routeCount.push(count);
      });
    }).finally(() => {
      this.loading = false;
    });
  }
};